.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 2.5em 0;
    font-size: var(--fs-h3);
  }
  .footer a {
    color: inherit;
    text-decoration: none;
  }
  .footer__link {
    font-weight: var(--fw-bold);
  }
  .footer__link:hover,
  .social-list__link:hover {
    opacity: 0.7;
  }
  .footer__link:hover {
    text-decoration: underline;
  }
  .social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
  }
  .social-list__item {
    margin: 0 0.5em;
  }
  .social-list__link {
    padding: 0.5em;
  }
  icon{
      color: white;
  }