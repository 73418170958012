.header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }
  .logo h1 {
    /* max-width: 300px; */
    font-size: 2rem;
  }
  .logo a {
    text-decoration: none;
    color: var(--clr-dark);
  }
  .logo:hover {
    cursor: pointer;
  }
  .nav {
    position: fixed;
    background: var(--clr-dark);
    color: var(--clr-light);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  
    transform: translateX(100%);
    transition: 250ms cubic-bezier(0.47, 0, 0.5, 1);
  }
  .nav__list {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .nav__link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: var(--fs-h2);
    text-decoration: none;
  }
  .nav__link:hover {
    color: var(--clr-accent);
  }
  .nav-toggle {
    padding: 0.5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
  }
  .nav-open .nav {
    transform: translateX(0);
  }
  .nav-open .nav-toggle {
    position: fixed;
  }
  .nav-open .hamburger {
    transform: rotate(0.625turn);
  }
  .nav-open .hamburger::after {
    opacity: 0;
  }
  .nav-open .hamburger::before {
    transform: rotate(0.25turn) translateX(-6px);
  }
  /* hamburger */
  .hamburger {
    display: block;
    position: relative;
  }
  .hamburger,
  .hamburger::before,
  .hamburger::after {
    background: var(--clr-accent);
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
  }
  .hamburger::before,
  .hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
  }
  .hamburger::before {
    top: 6px;
  }
  .hamburger::after {
    bottom: 6px;
  }
  