.portfolio__item {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .portfolio__img {
    transition: transform 750ms cubic-bezier(0.47, 0, 0.5, 1),
      opacity 250ms linear;
  }
  .portfolio__item:focus {
    position: relative;
    z-index: 2;
  }
  
  .portfolio__img:hover,
  .portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: 0.75;
  }