@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,900;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap);
/* typewriter */
/* font-family: 'Special Elite', cursive; */

/* 8-bit */
/* font-family: 'Press Start 2P', cursive; */

/* hand-written */
/* font-family: 'Amatic SC', cursive;
font-family: 'Press Start 2P', cursive; */

/* Primary */ /* font-family: 'Source Sans Pro', sans-serif; */

/* secondary */
/* font-family: 'Source Code Pro', monospace; */

/* barcode */
/* font-family: 'Libre Barcode 128 Text', cursive; */
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --ff-primary: "Source Sans Pro", sans-serif;
  --ff-secondary: "Source Code Pro", monospace;
  --ff-typewriter: "Special Elite", cursive;
  --ff-8bit: "Press Start 2P", cursive;

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #fff;
  --clr-dark: #303030;
  --clr-accent: #96ab98;
  --clr-accent-opac: #16e0bd99;

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-h4: 1.1rem;
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-medTitle: 2.5rem;
    --fs-h3: 1.5rem;
    --fs-body: 1.125rem;
  }
}
/* General styles */

html {
  scroll-behavior: smooth;
}
body {
  background: #fff;
  background: var(--clr-light);
  color: #303030;
  color: var(--clr-dark);
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-family: var(--ff-primary);
  font-size: 1rem;
  font-size: var(--fs-body);
  line-height: 1.6;
}
section {
  padding: 5em 2em;
}
img {
  display: block;
  max-width: 100%;
}
video {
  display: block;
  max-width: 100%;
}
strong {
  font-family: "Special Elite", cursive;
  font-weight: 900;
  font-weight: var(--fw-bold);
}
:focus {
  outline: 3px solid #96ab98;
  outline: 3px solid var(--clr-accent);
  outline-offset: 3px;
}

.btn {
  display: inline-block;
  padding: 0.5em 2.5em;
  background: #96ab98;
  background: var(--clr-accent);
  color: #303030;
  color: var(--clr-dark);
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  font-weight: var(--fw-bold);
  transition: -webkit-transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out;
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}
.btn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0px 0px 10px #fff;
}
/* Typography */
h1,
h2,
h3 {
  line-height: 1;
  margin: 0;
}
h1 {
  font-size: 3rem;
  font-size: var(--fs-h1);
}
h2 {
  font-size: 2.25rem;
  font-size: var(--fs-h2);
}
h3 {
  font-size: 1.25rem;
  font-size: var(--fs-h3);
}
.section__title {
  margin-bottom: 0.25em;
}
.section__title--intro strong {
  display: block;
}
.section__title--intro {
  font-weight: 300;
  font-weight: var(--fw-reg);
}
.section__subtitle {
  margin: 0;
  font-size: 1.25rem;
  font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
  background: #96ab98;
  background: var(--clr-accent);
  font-family: "Source Code Pro", monospace;
  font-family: var(--ff-secondary);
  padding: 0.25em 1em;
  margin-bottom: 1em;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.portfolio-item-individual {
    padding: 0 2em 2em;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .portfolio-item-individual p {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  a {
      color: #333;
      /* text-decoration: none; */
      margin: auto;
  }
  iframe {
      height: 400px;
      width: 650px;
      /* height: 50vh;
      width: 50vw; */
      margin: 0 auto;
  }
  .long-word {
    font-weight: 100;
    font-size: .8em;
  }
.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 2.5em 0;
    font-size: var(--fs-h3);
  }
  .footer a {
    color: inherit;
    text-decoration: none;
  }
  .footer__link {
    font-weight: var(--fw-bold);
  }
  .footer__link:hover,
  .social-list__link:hover {
    opacity: 0.7;
  }
  .footer__link:hover {
    text-decoration: underline;
  }
  .social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
  }
  .social-list__item {
    margin: 0 0.5em;
  }
  .social-list__link {
    padding: 0.5em;
  }
  icon{
      color: white;
  }
.header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }
  .logo h1 {
    /* max-width: 300px; */
    font-size: 2rem;
  }
  .logo a {
    text-decoration: none;
    color: var(--clr-dark);
  }
  .logo:hover {
    cursor: pointer;
  }
  .nav {
    position: fixed;
    background: var(--clr-dark);
    color: var(--clr-light);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  
    -webkit-transform: translateX(100%);
  
            transform: translateX(100%);
    transition: 250ms cubic-bezier(0.47, 0, 0.5, 1);
  }
  .nav__list {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .nav__link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: var(--fs-h2);
    text-decoration: none;
  }
  .nav__link:hover {
    color: var(--clr-accent);
  }
  .nav-toggle {
    padding: 0.5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
  }
  .nav-open .nav {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .nav-open .nav-toggle {
    position: fixed;
  }
  .nav-open .hamburger {
    -webkit-transform: rotate(0.625turn);
            transform: rotate(0.625turn);
  }
  .nav-open .hamburger::after {
    opacity: 0;
  }
  .nav-open .hamburger::before {
    -webkit-transform: rotate(0.25turn) translateX(-6px);
            transform: rotate(0.25turn) translateX(-6px);
  }
  /* hamburger */
  .hamburger {
    display: block;
    position: relative;
  }
  .hamburger,
  .hamburger::before,
  .hamburger::after {
    background: var(--clr-accent);
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  }
  .hamburger::before,
  .hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
  }
  .hamburger::before {
    top: 6px;
  }
  .hamburger::after {
    bottom: 6px;
  }
  
.intro {
    position: relative;
  }
  .intro__img {
    box-shadow: var(--bs);
  }
  @media (min-width: 600px) {
    .intro {
      display: grid;
      width: -webkit-min-content;
      width: min-content;
      margin: 0 auto;
      grid-template-areas:
        "img title"
        "img subtitle";
      grid-template-columns: -webkit-min-content -webkit-max-content;
      grid-template-columns: min-content max-content;
    }
    .intro__img {
      grid-area: img;
      min-width: 250px;
      position: relative;
      z-index: 2;
    }
    .section__subtitle--intro {
      align-self: start;
      grid-column: -1 /1;
      grid-row: 2;
      text-align: right;
      position: relative;
      left: -2em;
      width: calc(100% + 2em);
    }
  }
.my-services {
    /* background-color: var(--clr-dark);#252525 */
    background-color: #252525;

    background-image: url(/static/media/codeBackground.74f7ff06.jpg);
    color: var(--clr-light);
    background-size: cover;
    background-blend-mode: soft-light;
    text-align: center;
  }
  .section__title--services {
    color: var(--clr-accent);
    position: relative;
  }
  .section__title--services::after {
    content: "";
    display: block;
    width: 2.5em;
    height: 1px;
    margin: 0.5em auto 1em;
    /* background: currentColor; */
    background: var(--clr-light);
    opacity: 0.25;
  }
  .services {
    margin-bottom: 4em;
    
  }

  .service {
    max-width: 500px;
    margin: 0 auto;
  }
  @media (min-width: 800px) {
    .services {
      display: flex;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
    .service + .service {
      margin-left: 2em;
    }
  }

.about-me {
    max-width: 1000px;
    margin: 0 auto;
  }
  .about-me__img {
    box-shadow: var(--bs);
  }
  @media (min-width: 600px) {
    .about-me {
      display: grid;
      grid-template-columns: 1fr 200px;
      grid-template-areas:
        "title img"
        "subtitle img"
        "text img";
      grid-column-gap: 2em;
    }
  
    .section__title--about {
      grid-area: title;
    }
  
    .section__subtitle--about {
      grid-column: 1 / -1;
      grid-row: 2;
      position: relative;
      left: -1em;
      width: calc(100% + 2em);
      padding-left: 1em;
      padding-right: calc(200px + 4em);
    }
  
    .about-me__img {
      grid-area: img;
      position: relative;
      z-index: 2;
    }
  }
  
.portfolio__item {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .portfolio__img {
    transition: opacity 250ms linear,
      -webkit-transform 750ms cubic-bezier(0.47, 0, 0.5, 1);
    transition: transform 750ms cubic-bezier(0.47, 0, 0.5, 1),
      opacity 250ms linear;
    transition: transform 750ms cubic-bezier(0.47, 0, 0.5, 1),
      opacity 250ms linear,
      -webkit-transform 750ms cubic-bezier(0.47, 0, 0.5, 1);
  }
  .portfolio__item:focus {
    position: relative;
    z-index: 2;
  }
  
  .portfolio__img:hover,
  .portfolio__item:focus .portfolio__img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0.75;
  }
.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;
  }
  .section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-top: 2em;
  }
  .portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    max-width: 1050px;
    margin: 0 auto;
  }
