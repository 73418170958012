.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;
  }
  .section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-top: 2em;
  }
  .portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    max-width: 1050px;
    margin: 0 auto;
  }