.intro {
    position: relative;
  }
  .intro__img {
    box-shadow: var(--bs);
  }
  @media (min-width: 600px) {
    .intro {
      display: grid;
      width: min-content;
      margin: 0 auto;
      grid-template-areas:
        "img title"
        "img subtitle";
      grid-template-columns: min-content max-content;
    }
    .intro__img {
      grid-area: img;
      min-width: 250px;
      position: relative;
      z-index: 2;
    }
    .section__subtitle--intro {
      align-self: start;
      grid-column: -1 /1;
      grid-row: 2;
      text-align: right;
      position: relative;
      left: -2em;
      width: calc(100% + 2em);
    }
  }